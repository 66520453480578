// @flow
import React from "react";
import styled from "styled-components";

import { __, formatTimeXX } from "../utils";

type TimerProps = {
  seconds: string,
  minutes: string,
  hours: string,
  textColor: string,
  digitsColor: string,
  timerColor: string,
  size: "small" | "big" | "huge",
  showLabels?: boolean,
  theme?: "dark" | "light" | "purpleOrange" | "nightBlue",
  translateLabels?: boolean,
  showDays?: boolean,
  foldableDigits?: boolean,
  showTimerDigitCouplesAsOneOnMobile?: boolean
};

const THEMES = {
  dark: {
    backgroundColor: "#353535",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  light: {
    backgroundColor: "#fafafa",
    textColor: "#444444",
    border: "1px solid #eaeaea",
    valuesColor: "#000000"
  },
  purpleOrange: {
    backgroundImage: "linear-gradient(to bottom, #642b73, #e46952)",
    textColor: "#ffffff"
  },
  cyan: {
    backgroundColor: "#17bed0",
    textColor: "#ffffff",
  },
  nightBlue: {
    backgroundColor: "#075398",
    textColor: "#ffffff",
  },
  blue: {
    backgroundColor: "#092189",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  frenchBlue: {
    backgroundColor: "#1541a4",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  purpleBlue: {
    backgroundColor: "#3c3b6e",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  contrast: {
    backgroundImage: "linear-gradient(to top, #ffffff, #eeeeee, #ffffff)",
    textColor: "rgba(255, 255, 255, 0.5)",
    valuesColor: "#101112"
  },
  valentineRed: {
    backgroundColor: "#d83f56",
    textColor: "#707070",
    valuesColor: "#ffffff"
  },
  green: {
    backgroundColor: "#93c745",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  green2: {
    backgroundColor: "#07b459",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  // Mexico 2021
  green3: {
    backgroundColor: "#189552",
    textColor: "#707070",
    valuesColor: "#ffffff"
  },
  yellowOrange: {
    backgroundImage: "linear-gradient(to bottom, #fff262, #d29400 49%, #fae47b)",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  blueGradient: {
    backgroundImage: "linear-gradient(to bottom, #219fdf, #1586c3)",
    textColor: "#999999",
    valuesColor: "#ffffff"
  },
  yellow: {
    backgroundColor: "#fdb428",
    textColor: "#ffffff",
  },
  orange: {
    backgroundColor: "#fc7f23",
    textColor: "#ffffff",
  },
  muertosPurple: {
    backgroundColor: "#a11dc6",
    textColor: "#ffffff",
  },
  xmasBlue: {
    backgroundColor: "#17bed0",
    textColor: "#ffffff",
  },
  default: {
    backgroundColor: "#353535",
    textColor: "#ffffff"
  },
  cyberMonddayBlue: {
    backgroundColor: "#204ad6",
    textColor: "#ffffff"
  },
  cny2022Gold: {
    backgroundImage: "linear-gradient(to bottom, #f6b129, #ed8f23)",
    textColor: "#ffffff",
  },
  easter2022Green: {
    backgroundColor: "#96c92b",
    textColor: "#ffffff"
  },
  kingsDayBlue: {
    backgroundImage: "linear-gradient(to bottom, #171095, #121b4b)",
    textColor: "#ffffff",
  },
  easter2022pink: {
    backgroundColor: "#fd4559",
    textColor: "#ffffff"
  },
  italy2022green: {
    backgroundColor: "#008730",
    textColor: "#ffffff"
  },
  usa2022: {
    backgroundColor: "#0c3d90",
    textColor: "#ffffff",
  },
  france2022: {
    backgroundColor: "#00338d",
    textColor: "#ffffff"
  },
  backToSchool2022: {
    backgroundColor: "#008187",
    textColor: "#ffffff"
  },
  backToSchool2022v2: {
    backgroundColor: "#08a0a7",
    textColor: "#ffffff"
  },
  laborDay2022: {
    backgroundColor: "#0b3261",
    textColor: "#ffffff"
  },
  moonFestival: {
    backgroundColor: "#5d3f96",
    textColor: "#ffffff"
  },
  germanyNationalDay: {
    backgroundColor: "#ffce00",
    textColor: "#ffffff"
  },
  canadaThanksgiving2022: {
    backgroundColor: "#fda824",
    textColor: "#ffffff"
  },
  spainNationalDay2022: {
    backgroundColor: "#e0262c",
    textColor: "#ffffff"
  },
  halloween2022v2: {
    backgroundColor: "#f27221",
    textColor: "#ffffff"
  },
  halloween2022: {
    backgroundColor: "#500251",
    textColor: "#ffffff"
  },
  singlesDay2022: {
    backgroundColor: "#d5001d",
    textColor: "#ffffff"
  },
  diaDeLosMuertos: {
    backgroundColor: "#39074e",
    textColor: "#fff"
  },
  cyberMonday2022: {
    backgroundColor: "#254dd1",
    textColor: "#fff"
  },
  xmas2022: {
    backgroundColor: "#1e4653",
    textColor: "#fff"
  },
  xmas2022v2: {
    backgroundImage: "linear-gradient(to bottom, #ffd75e, #c98a1a)",
    textColor: "#fff"
  },
  bfWhite: {
    backgroundImage: "linear-gradient(to bottom, #fff 27%, #bcbcbc 52%, #fff 77%)",
    textColor: "rgba(255, 255, 255, 0.4)",
    valuesColor: "#000"
  },
  xmas2022CS: {
    backgroundImage: "linear-gradient(to bottom, #474747 27%, #111 52%, #282828 77%)",
    textColor: "#868789",
    valuesColor: "#fff"
  },
  lunar2023: {
    backgroundColor: "#e9a33c",
    textColor: "#ffffff",
  },
  lunar2023v2: {
    backgroundColor: "#d8991f",
    textColor: "#ffffff",
  },
  valentine2023: {
    backgroundColor: "#ae009d",
    textColor: "#ffffff",
  },
  sakura2023: {
    backgroundImage: "linear-gradient(to bottom, #9edbf7, #2b7a9a);",
    textColor: "#ffffff",
  },
  easter2023: {
    backgroundColor: "#fa5499",
    textColor: "#fff"
  },
  easter2023v2: {
    backgroundColor: "#da4f6a",
    textColor: "#fff"
  },
  StGeorge2023: {
    backgroundImage: "linear-gradient(to bottom, #17bed0, #186a8b)",
    textColor: "#fff"
  },
  KingsDay2023: {
    backgroundColor: "#015cad",
    textColor: "#fff"
  },
  familyDay2023: {
    backgroundImage: "#linear-gradient(to bottom, #1bbed0, #1495a4)",
    textColor: "#fff"
  },
  Italy2023: {
    backgroundColor: "#fff",
    textColor: "#014321"
  },
  Portugal2023: {
    backgroundImage: "#linear-gradient(to top, #25738d, #3ea8ab)",
    textColor: "#fff"
  },
  DragonBoat2023: {
    backgroundImage: "#linear-gradient(to top, #ff6408, #c90505)",
    textColor: "#fff"
  },
  USA2023: {
    backgroundColor: "#e93b51",
    textColor: "#fff"
  },
  France2023: {
    backgroundColor: "#f02f40",
    textColor: "#fff"
  }
};

export const TimerTemplate = ({
  seconds,
  minutes,
  hours,
  textColor,
  digitsColor,
  timerColor,
  size = "big",
  showLabels,
  theme,
  translateLabels = true,
  showDays,
  foldableDigits = false,
  showTimerDigitCouplesAsOneOnMobile = false
}: TimerProps) => {
  let themeStyle = THEMES[theme] ? THEMES[theme] : THEMES["default"];

  if (timerColor) {
    themeStyle.backgroundColor = "#" + timerColor;
  }

  if (textColor) {
    themeStyle.textColor = "#" + textColor;
  }

  const renderDays = (_days) => {
    let daysSplit = _days.toString().split('');

    return (
      <span className="cards-wrapper">
        <ValueWrapper size={size} className="values-wrapper">
          {daysSplit.map((digit, i) => {
            return <Card key={i} themeStyle={themeStyle} size={size} digitsColor={digitsColor} foldableDigits={foldableDigits} showTimerDigitCouplesAsOneOnMobile={showTimerDigitCouplesAsOneOnMobile} className="timer-card">{digit}</Card>
          })}
        </ValueWrapper>
        {showLabels ? <Label themeStyle={themeStyle} className="timer-label">{translateLabels ? __("days") : "days"}</Label> : null}
      </span>
    );
  };

  const renderHours = (_hours) => {
    const hoursLenght = String(_hours).length;
    const hoursCardsArr = [];
    let hoursSplit = _hours.toString().split('');

    for (var i = 0; i < hoursLenght; i++) {
      hoursCardsArr.push(<Card key={i} themeStyle={themeStyle} size={size} digitsColor={digitsColor} foldableDigits={foldableDigits} showTimerDigitCouplesAsOneOnMobile={showTimerDigitCouplesAsOneOnMobile} className="timer-card" />);
    };
  
    return (
      <span className="cards-wrapper">
        <ValueWrapper size={size} className="values-wrapper">
          {hoursSplit.map((digit, i) => {
            return <Card key={i} themeStyle={themeStyle} size={size} digitsColor={digitsColor} foldableDigits={foldableDigits} showTimerDigitCouplesAsOneOnMobile={showTimerDigitCouplesAsOneOnMobile} className="timer-card">{digit}</Card>
          })}
        </ValueWrapper>
        {showLabels ? <Label themeStyle={themeStyle} className="timer-label">{translateLabels ? __("hours") : "hours"}</Label> : null}
      </span>
    );
  }
  let minutesSplit = minutes.toString().split('');
  const renderMinutes = () => (
    <span className="cards-wrapper">
      <ValueWrapper size={size} className="values-wrapper">
        {minutesSplit.map((digit, i) => {
          return <Card key={i} themeStyle={themeStyle} size={size} digitsColor={digitsColor} foldableDigits={foldableDigits} showTimerDigitCouplesAsOneOnMobile={showTimerDigitCouplesAsOneOnMobile} className="timer-card">{digit}</Card>
        })}
      </ValueWrapper>
      {showLabels ? <Label themeStyle={themeStyle} className="timer-label">{translateLabels ? __("minutes") : "minutes"}</Label> : null}
    </span>
  );
  let secondsSplit = seconds.toString().split('');
  const renderSeconds = () => (
    <span className="cards-wrapper">
      <ValueWrapper size={size} className="values-wrapper">
        {secondsSplit.map((digit, i) => {
          return <Card key={i} themeStyle={themeStyle} size={size} digitsColor={digitsColor} foldableDigits={foldableDigits} showTimerDigitCouplesAsOneOnMobile={showTimerDigitCouplesAsOneOnMobile} className="timer-card">{digit}</Card>
        })}
      </ValueWrapper>
      {showLabels ? <Label themeStyle={themeStyle} className="timer-label">{translateLabels ? __("seconds") : "seconds"}</Label> : null}
    </span>
  );

  const renderDaysRepresentaion = () => {
    const days = formatTimeXX(Math.floor(hours / 24));
    const hoursLeft = formatTimeXX(hours % 24);

    return (
      <CounterCont themeStyle={themeStyle} size={size} showTimerDigitCouplesAsOneOnMobile={showTimerDigitCouplesAsOneOnMobile}>
        {renderDays(days)}
        <i className="timer-sep">:</i>
        {renderHours(hoursLeft)}
        <i className="timer-sep">:</i>
        {renderMinutes(minutes)}
      </CounterCont>
    )
  };

  const renderHoursRepresentaion = () => {
    return (
      <CounterCont themeStyle={themeStyle} size={size} showTimerDigitCouplesAsOneOnMobile={showTimerDigitCouplesAsOneOnMobile}>
        {renderHours(hours)}
        <i className="timer-sep">:</i>
        {renderMinutes()}
        <i className="timer-sep">:</i>
        {renderSeconds()}
      </CounterCont>
    )
  };

  return showDays ? renderDaysRepresentaion() : renderHoursRepresentaion();
};

const sizes = {
  big: {
    height: 47,
    width: 35,
    font: 26,
    top: 10,
    left: 12,
    spacing: 24,
    weight: "normal"
  },
  small: {
    height: 32,
    width: 24,
    font: 19,
    top: 5,
    left: 9,
    spacing: 17,
    weight: "bold"
  },
  medium: {
    height: 40,
    width: 30,
    font: 24,
    top: 6,
    left: 11,
    spacing: 17,
    weight: "bold"
  },
  mediumV2: {
    height: 40,
    width: 32,
    font: 22,
    top: 6,
    left: 11,
    spacing: 17,
    weight: "bold",
    radius: 6,
    timerSepFontSize: 19,
    digitsMarginLeftRight: 1 
  },
  huge: {
    height: 54,
    width: 40,
    font: 31,
    top: 10,
    left: 13,
    spacing: 26,
    weight: 500
  }
};

const CounterCont = styled.div`
  display: flex;
  justify-content: center;

  i {
    line-height: ${props => sizes[props.size].height}px;
    margin: 0px 4px;
    font-size: ${props => sizes[props.size].timerSepFontSize ? sizes[props.size].timerSepFontSize : sizes[props.size].font}px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props =>
      props.themeStyle["backgroundColor"]
        ? props.themeStyle["backgroundColor"]
        : "#ffffff"};

    @media (max-width: 350px) {
      margin: 0px 6px;
    }
  }
  .cards-wrapper {
    white-space: nowrap;
  }

  ${props => {
    if (props && props.showTimerDigitCouplesAsOneOnMobile) {
      return `
      @media (max-width: 768px) {
        align-items: center;

        i {
          line-height: initial;
        }
        .cards-wrapper {
          padding: 8px;
          margin: 0px ${sizes[props.size].digitsMarginLeftRight ? sizes[props.size].digitsMarginLeftRight : 2}px;
          border-radius: ${sizes[props.size].radius ? sizes[props.size].radius : 4.4}px;
          box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.07);
          border: ${props.themeStyle["border"]
              ? props.themeStyle["border"]
              : "none"};
          background-color: ${props.themeStyle["backgroundColor"]
              ? props.themeStyle["backgroundColor"]
              : "#353535"};
          background-image: ${props.themeStyle["backgroundImage"]
              ? props.themeStyle["backgroundImage"]
              : "none"};
        }
        
      }
      `;
    }
  }};
`;

const ValueWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const Value = styled.span`
  position: absolute;
  color: ${props =>
    props.themeStyle["valuesColor"]
      ? props.themeStyle["valuesColor"]
      : "#ffffff"};
  font-size: ${props => sizes[props.size].font}px;
  letter-spacing:  ${props => sizes[props.size].spacing}px;
  left: ${props => sizes[props.size].left}px;
  top:  ${props => sizes[props.size].top}px;
  z-index: 1;

  font-weight: ${props => sizes[props.size].weight};
  font-style: normal;
  font-stretch: normal;
  text-align: center;
`;

const Card = styled.div`
  font-size: ${props => sizes[props.size].font}px;
  font-weight: bold;
  text-align: center;
  line-height: ${props => sizes[props.size].height}px;
  display: inline-block;
  width: ${props => sizes[props.size].width}px;
  height: ${props => sizes[props.size].height}px;
  margin: 0px ${props => sizes[props.size].digitsMarginLeftRight ? sizes[props.size].digitsMarginLeftRight : 2}px;
  border-radius: ${props => sizes[props.size].radius ? sizes[props.size].radius : 4.4}px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.07);
  border: ${props =>
    props.themeStyle["border"]
      ? props.themeStyle["border"]
      : "none"};
  background-color: ${props =>
    props.themeStyle["backgroundColor"]
      ? props.themeStyle["backgroundColor"]
      : "#353535"};
  background-image: ${props =>
    props.themeStyle["backgroundImage"]
      ? props.themeStyle["backgroundImage"]
      : "none"};

  ${props => {
    if (props && props.showTimerDigitCouplesAsOneOnMobile) {
      return `
        @media (max-width: 768px) {
          line-height: initial;
          width: initial;
          height: initial;
          margin: 0;
          border-radius: unset;
          box-shadow: unset;
          border: none;
          background-color: unset;
          background-image: none;
        }
      `;
    }
  }};

  color: ${({ digitsColor, themeStyle }) => {
    if (digitsColor) {
      return "#" + digitsColor
    }

    if (themeStyle["textColor"]) {
      return themeStyle["textColor"]
    }

    return "inherit"
  }};
  box-sizing: border-box;
  position: relative;
  ${props => {
      if (props.foldableDigits) {
        return `&:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          height: 1px;
          margin-top: -0.5px;
          background-color: ${props.themeStyle["backgroundColor"]
            ? props.themeStyle["backgroundColor"]
            : "#353535"};
          background-image: ${props.themeStyle["backgroundImage"]
            ? props.themeStyle["backgroundImage"]
            : "none"};
          z-index: 2;
        }` 
      }
      return ""
  }}
`;
const Label = styled.div`
  margin-top: 6px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: ${props =>
    props.themeStyle["textColor"]
      ? props.themeStyle["textColor"]
      : "#ffffff"};
`;
