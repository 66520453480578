import React, { useState, useCallback } from "react";
import styled, { css, keyframes } from "styled-components";

import { DOWNLOAD_DRIVE_BUTTON_OPTIONS } from "../downloadDriveSettings";
import Button from "../components/ButtonDefault"

type Props = {
  os: "windows" | "macos" | "linux",
  arrange: "vertical" | "horizontal",
  align?: "center"
}

const DownloadDriveButton = ({
  os,
  arrange = "horizontal",
  align,
  color = "#17bed0",
  buttonColor = "cyan",
}: Props) => {
  const [isSelectMenuOpened, setIsSselectMenuOpened] = useState(false);

  const renderSelectMenu = ({ text, options }) => {
    const renderOptions = () => {
      return options.map(({ optionText, link }) => {
        return (
          <Option
            href={link}
            isSelectMenuOpened={isSelectMenuOpened}
          >
            {optionText}
          </Option>
        )
      })
    }
    return (
      <SelectContainer
        color={color}
        onMouseEnter={() => setIsSselectMenuOpened(true)}
        onMouseLeave={() => setIsSselectMenuOpened(false)}
      >
        <DropDownButton color={color} isSelectMenuOpened={isSelectMenuOpened}>{text}</DropDownButton>
        <OptionList isSelectMenuOpened={isSelectMenuOpened}>
          {renderOptions()}
        </OptionList>
      </SelectContainer>
    )
  }

  const renderButton = (buttonsArray) => {
    return (
      <ButtonsContainer arrange={arrange} align={align}>
        {
          buttonsArray.map(({ type, text, link, description, options }, index) => {
            if (type === "button") {
              return (
                <React.Fragment>
                  <ButtonWrapper>
                    <Button
                      color={buttonColor}
                      href={link}
                      borderRadius="10"
                      textSize="big"
                      height="46"
                    >
                      {text}
                    </Button>
                    {description ? <Description>{description}</Description> : null}
                  </ButtonWrapper>
                  {index < buttonsArray.length - 1 ? <Conjunction arrange={arrange}>{/*__('or')*/}</Conjunction> : null}
                </React.Fragment>
              )
            } else if (type === "select") {
              return (
                <React.Fragment>
                  <ButtonWrapper>
                    {renderSelectMenu({ text, options })}
                    {description ? <Description>{description}</Description> : null}
                  </ButtonWrapper>
                  {index < buttonsArray.length - 1 ? <Conjunction arrange={arrange}>{/*__('or')*/}</Conjunction> : null}
                </React.Fragment>
              )
            }
          })
        }
      </ButtonsContainer>
    )
  }

  const downloadDriveButtonData = DOWNLOAD_DRIVE_BUTTON_OPTIONS[os];

  if (!downloadDriveButtonData) {
    return <NoAppAvailable>{__("downoad_drive_not_supported_os")}</NoAppAvailable>
  }

  return renderButton(downloadDriveButtonData);
}

export default DownloadDriveButton;

const showFromTop = keyframes`
  0%   { transform: translateY(-10%); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
`;

const SelectContainer = styled.div`
  font-size: 15px;
  height: 46px;
  border-radius: 10px;
  font-family: Roboto;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  padding: 0 30px;
  margin: 0 auto;
  display: inline-block;
  box-sizing: border-box;
  opacity: 1;
  transition: all 250ms ease-in-out;
  width: 100%;
  background-color: #fff;
  background-image: url(${require("../images/black-arrow.png")});
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 13px;
  border: 1px solid ${(props) => props.color};
`;

const OptionList = styled.div`
  display: ${({ isSelectMenuOpened }) => isSelectMenuOpened ? "block" : "none"};
  opacity: ${({ isSelectMenuOpened }) => isSelectMenuOpened ? "1" : "0"};
  width: 100%;
  left: 0;
  padding: 0px 10px;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 250ms ease-in-out;
  padding: 6px 0px;
`;

const Option = styled.a`
  width: 100%;
  display: block;
  opacity: ${({ isSelectMenuOpened }) => isSelectMenuOpened ? "1" : "0"};
  color: #26b8c7;
  padding: 6px 0px;
  box-sizing: border-box;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
  
  ${({ isSelectMenuOpened }) => {
    if (isSelectMenuOpened) {
      return css`animation: ${showFromTop} 0.4s alternate;`;
    }
  }}
  
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  min-width: 280px;
`;

const Description = styled.div`
  margin-top: 12px;
  padding: 0px 15px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.07px;
  text-align: center;
  color: #878787;
`;

const ButtonsContainer = styled.div`
  display: flex;
  ${({ arrange }) => {
    if (arrange && arrange === "vertical") {
      return `flex-direction: column;`;
    } else if (arrange && arrange === "horizontal") {
      return `flex-direction: row;`;
    }
  }}

  ${({ align }) => {
    if (align && align === "center") {
      return (`
        justify-content: center;
      `);
    } else {
      return `align-items: flex-start;`;
    }
  }}

  @media (max-width: 620px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Conjunction = styled.div`
  color: #b5b5b5;
  font-size: 15px;
  font-weight: 100;
  line-height: 12px;

  ${({ arrange }) => {
    if (arrange === "vertical") {
      return `
        margin: 14px auto;
      `;
    } else if (arrange === "horizontal") {
      return `
        position: relative;
        top: 14px;
        margin: 0px 12px;
      `;
    }
  }}

  @media (max-width: 620px) {
    margin: 14px auto;
    position: static;
  }
`;

const DropDownButton = styled.div`
  line-height: 46px;
  color: ${(props) => props.isSelectMenuOpened ? "#444" : props.color};
transition: color 300ms ease -in -out;
`;

const NoAppAvailable = styled.div`
padding: 12px;
box - sizing: border - box;
text - align: center;
border: solid 2px #fcf2d6;
background - color: #fff8e1;
border - radius: 6px;
width: 100 %;
max - width: 640px;
margin: 0 auto;
`;