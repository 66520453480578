export const ERRORS_TRANSLATION_KEYS = {
  "usebonuscode": {
    2012: "You have entered an invalid code",
    2014: "activate_promocode_notification",
    2074: "You have entered an expired code",
    2077: "error_2077",
    2092: "This promo code was already used",
    2109: "error_2109", //You are already a premium user.
    2219: "Bonus code not applied. Bonus code doesn&#39;t increase free storage.",
    2362: "this_promotion_not_valid_for_country",
    2368: "Valentine_gift_code_error",
  },
  "usebonuscodeandverify": {
    2012: "You have entered an invalid code",
    2074: "You have entered an expired code",
    2077: "error_2077",
    2092: "This promo code was already used",
    2109: "error_2109", //You are already a premium user.
    2219: "Bonus code not applied. Bonus code doesn&#39;t increase free storage.",
  }
}