// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { pcloudPassScore } from "@pcloud/password-strength";
import { MIN_PASSWORD_STRENGTH } from "../constants";

// Password strength
// 0	      0	-
// 1...29	  1	weak
// 30...39	2	weak
// 40...59	3	moderate
// 60...69	4	moderate
// 70...99	5	strong
// 100...	  6	strong

const PasswordStrengthMeter = ({
  password,
  numberOfSections = 6,
  height = "2px",
  template = "v2",
  getStrengthScore = () => {},
  onRequirementsMeet = () => {},
  onRequerementsReject = () => {}
}) => {
  const [strength, setStrength] = useState(0);

  useEffect(() => {
    const strengthScore = pcloudPassScore.password_score(password);
    let colorStrength = 0;
    getStrengthScore(strengthScore);
    
    if (strengthScore >= 1 && strengthScore <= 29) {
      colorStrength = "weak1";
    } else if (strengthScore >= 30 &&  strengthScore <= 39) {
      colorStrength = "weak2";
    } else if (strengthScore >= 40 &&  strengthScore <= 59) {
      colorStrength = "moderate1";
    } else if (strengthScore >= 60 && strengthScore <= 69) {
      colorStrength = "moderate2";
    } else if (strengthScore >= 70 && strengthScore <= 99) {
      colorStrength = "strong1";
    } else if (strengthScore > 99) {
      colorStrength = "strong2";
    };
    
    setStrength(colorStrength);
    console.log("🚀 ~ file: PasswordStrengthMeter.js ~ line 32 ~ useEffect@@@@ ~ strengthScore", strengthScore)
    console.log("🚀 ~ file: PasswordStrengthMeter.js ~ line 33 ~ useEffect@@@@ ~ colorStrength", colorStrength)
    
    if (strengthScore >= MIN_PASSWORD_STRENGTH) {
      onRequirementsMeet();
    } else {
      onRequerementsReject();
    }
  }, [password, numberOfSections]);

  const renderScoreSections = () => {
    const arr = [];

    for (let i = 0; i < numberOfSections; i++) {
      arr.push(<PasswordStrengthSection margin={numberOfSections - 1 == i ? "0 0 0 2px" : "0 2px"} className={template === "v2" ? 'pass-strength-section-v2' : 'pass-strength-section-v1'} height={height} strength={strength} key={`${strength}-${i}`} />)
    };

    console.log("arr", arr);

    return arr;
  }
  return (
    template == "v2" ?
    <PasswordStrengthContainerV2>
        {renderScoreSections()}
      <PasswordStrengthBacgrkound className={strength} />
      <PasswordStrengthSecondBackgrkound className={strength}/>
    </PasswordStrengthContainerV2>
    :
    <PasswordStrengthContainer className={strength}>
      {renderScoreSections()}
    </PasswordStrengthContainer>
  );
};

export default PasswordStrengthMeter;

const PasswordStrengthSection = styled.div`
  height: ${({height}) => height};
  border-radius: 3px;
  background-color: #000;
  display: inline-block;
  width: 100%;
  margin: ${({margin}) => margin ? margin : "0 2px"};
  
  &.pass-strength-section-v1 {
    border-radius: 1px;
    background-color: #ddd;
    transition: background-color 200ms ease-in-out;
  }

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }
`;

const PasswordStrengthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;

  &.weak1 {
    ${PasswordStrengthSection} {
      &:nth-of-type(1) {
        background-color: #f73c3c;
      }
    }
  }

  &.weak2 {
    ${PasswordStrengthSection} {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        background-color: #f73c3c;
      }
    }
  }

  &.moderate1 {
    ${PasswordStrengthSection} {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-color: #ff9100;
      }
    }
  }

  &.moderate2 {
    ${PasswordStrengthSection} {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        background-color: #ff9100;
      }
    }
  }

  &.strong1 {
    ${PasswordStrengthSection} {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        background-color: #5cb900;
      }
    }
  }

  &.strong2 {
    ${PasswordStrengthSection} {
      background-color: #5cb900;
    }
  }
`;

const PasswordStrengthContainerV2 = styled.div`
  mix-blend-mode: screen;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  position: relative;
  background: #fff;
`;

const PasswordStrengthBacgrkound = styled.div`
  mix-blend-mode: screen;
  transition: all 600ms cubic-bezier(.22,.85,.45,.98);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 100%;
  background-color: #ddd;

  &.weak1 {
    background-color: #f73c3c;
    width: 16.66%;
  }

  &.weak2 {
    background-color: #f73c3c;
    width: 33.33%;
  }

  &.moderate1 {
    background-color: #ff9100;
    width: 49.99%;
  }

  &.moderate2 {
    background-color: #ff9100;
    width: 66.66%;
  }

  &.strong1 {
    background-color: #5cb900;
    width: 83.31%;
  }

  &.strong2 {
    background-color: #5cb900;
    width: 100%;
  }
`;

const PasswordStrengthSecondBackgrkound = styled.div`
  mix-blend-mode: screen;
  transition: all 600ms cubic-bezier(.22,.85,.45,.98);
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #ddd;

  &.weak1 {
    width: 83.31%;
  }

  &.weak2 {
    width: 66.66%;
  }

  &.moderate1 {
    width: 49.99%;
  }

  &.moderate2 {
    width: 33.33%;
  }

  &.strong1 {
    width: 16.66%;
  }

  &.strong2 {
    width: 0px;
  }
`;