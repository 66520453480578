import { hrefSearch, setcookie, rcookie } from "./utils";

export const get6HData = () => {
  let data = null;

  const sub2ParamUrl = hrefSearch("sub2");
  const sub2Cookie = rcookie("sub2");

  if (sub2ParamUrl && sub2ParamUrl !== sub2Cookie) {
    return sub2ParamUrl;
  } else if (sub2Cookie) {
    return sub2Cookie;
  }

  return null;
}

export const set6HData = (newValue) => {
  const sub2Cookie = rcookie("sub2");

  if (sub2Cookie && sub2Cookie !== newValue) {
    setcookie("sub2", newValue, 395);
  } else if (!sub2Cookie && newValue) {
    setcookie("sub2", newValue, 395);
  }
}

export const send6HData = (apiMethod, sub2Param) => {
  if (!sub2Param) {
    return;
  };

  apiMethod("affiliate/sixhagency/send", {sub2: sub2Param}, () => {}, {})
}