// @flow
import React, { useState, useEffect, useRef } from "react";

import { formatTimeXX } from "../utils";

type TimerProps = {
  timeExpire: string | number,
  textColor: string,
  digitsColor?: string,
  timerColor: string,
  theme: string,
  size: "small" | "big" | "huge",
  showLabels?: boolean,
  timeExpireCallback: () => void,
  showDays: boolean,
  foldableDigits?: boolean,
  renderTimer: ({
    seconds: string,
    minutes: string,
    hours: string,
    textColor: string,
    digitsColor?: string,
    timerColor: string,
    theme: string,
    size: "small" | "big" | "huge",
    showLabels?: boolean,
    showDays?: boolean,
    foldableDigits?: boolean,
    showTimerDigitCouplesAsOneOnMobile?: boolean
  }) => void
};

const TimerContainer = ({
  timeExpire,
  theme,
  timeExpireCallback = () => {},
  renderTimer = () => null,
  textColor,
  digitsColor,
  timerColor,
  showLabels = true,
  size = "big",
  showDays,
  foldableDigits = false,
  showTimerDigitCouplesAsOneOnMobile = false
}: TimerProps) => {  
  
  const countDown = () => {
    const timeNow = new Date().getTime();

    const timeBetween = timeExpire - timeNow;
    
    if (timeBetween <= 0) {
      // Expired timer
      timeExpireCallback();

      if (timerInterval && timerInterval.current) {
        clearInterval(timerInterval.current);
      }

      return {newHours: 0, newMinutes: 0, newSeconds: 0};
    }

    const newHours = Math.floor(timeBetween / (1000 * 60 * 60));

    const newMinutes = Math.floor(
      (timeBetween % (1000 * 60 * 60)) / (1000 * 60)
    );

    const newSeconds = Math.floor((timeBetween % (1000 * 60)) / 1000);
    return {newHours, newMinutes, newSeconds};
  };

  // Set initial counter
  const {newHours, newMinutes, newSeconds} = countDown();

  const [seconds, setSeconds] = useState(formatTimeXX(newSeconds));
  const [minutes, setMinutes] = useState(formatTimeXX(newMinutes));
  const [hours, setHours] = useState(formatTimeXX(newHours));

  const timerInterval = useRef(null);
  

  useEffect(() => {
    return () => {
      clearInterval(timerInterval.current);
    };
  }, []);

  useEffect(() => {
    initTimer();
  
    return () => {
      clearInterval(timerInterval.current);
    };
  }, [timeExpire]);

  const initTimer = () => {
    timerInterval.current = setInterval(() => {
      const {newHours, newMinutes, newSeconds} = countDown();

      updateCounterUI(newHours, newMinutes, newSeconds);
      
    }, 1000);
  }

  const updateCounterUI = (newHours, newMinutes, newSeconds) => {
    setSeconds(formatTimeXX(newSeconds));
    setMinutes(formatTimeXX(newMinutes));
    setHours(formatTimeXX(newHours));
  };

  return renderTimer({
    seconds,
    minutes,
    hours,
    textColor,
    digitsColor,
    timerColor,
    showLabels,
    theme,
    size,
    showDays,
    foldableDigits,
    showTimerDigitCouplesAsOneOnMobile
  });
};

export default TimerContainer;
