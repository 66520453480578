// @flow

export const formatCardNumber = (value) => {
  const groups = value.match(/.{1,4}/g);
  return groups ? groups.join(" ") : "";
}; 

export const formatExpDatetoMMYY = (inputvalue: string) => {
  let formatedValue = inputvalue;

  formatedValue = formatedValue
    .replace(
      /^([1-9]\/|[2-9])$/g,
      "0$1 / " // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      "$1 / " // 11 > 11/
    )
    .replace(
      /^1([3-9])$/g,
      "01 / $1" // 13 > 01/3 //UPDATED by NAVNEET
      // ).replace(
      //   /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    )
    .replace(
      /^0\/|0+$/g,
      "0" // 0/ > 0 and 00 > 0 //UPDATED by NAVNEET
    )
    .replace(
      /[^\d|^\/]*/g,
      "" // To allow only digits and `/` //UPDATED by NAVNEET
    )
    .replace(
      /\/\//g,
      "/" // Prevent entering more than 1 `/`
    );

  return formatedValue;
};

export const preventEnteringChars = event => {
  const key = event.which || event.keyCode || event.charCode;
  const pressedCharacter = String.fromCharCode(key);

  if (!/[0-9 ]/.test(pressedCharacter)) {
    event.preventDefault();
  }
};

export const preventEnteringNumbers = event => {
  const key = event.which || event.keyCode || event.charCode;
  const pressedCharacter = String.fromCharCode(key);

  if (!/[a-zA-Z '-]/.test(pressedCharacter)) {
    event.preventDefault();
  }
};

export const preventSymbolsOnCCHolderName = event => {
  const key = event.which || event.keyCode || event.charCode;
  const pressedCharacter = String.fromCharCode(key);

  if (!/[a-zA-Z0-9\s\-!@#$%&*()_+=§/.,<>"\\|\]{};:'`?~±€₤]/.test(pressedCharacter)) {
    event.preventDefault();
  }
}

export const findCardType = (cardNumber: string): creditCardValidation => {
  const creditCardValidations = [
    {
      name: "amex",
      range: "34, 37",
      valid_length: ["15"]
    },
    {
      name: "diners",
      range: "301, 302, 303, 304, 305, 36",
      valid_length: ["16"]
    },
    {
      name: "jcb",
      range: "352, 353, 354, 355, 356, 357, 358",
      valid_length: ["16"]
    },
    {
      name: "visa",
      range: "4",
      valid_length: ["19", "18", "17", "16", "15", "14", "13"]
    },
    {
      name: "mastercard",
      range: "50, 51, 52, 53, 54, 55, 222",
      valid_length: ["16"]
    },
    {
      name: "discover",
      range: "6011, 622, 644, 645, 646, 647, 648, 649",
      valid_length: ["16"]
    }
  ];

  return creditCardValidations.find((cardTemplate: creditCardValidation): | creditCardValidation
    | false => {
    let isThereAMatch = cardTemplate.range
      .split(", ")
      .some((range: string) => {
        const rangeLength = range.length;
        return range === cardNumber.slice(0, rangeLength);
      });

    return isThereAMatch;
  });
};

export const getExpiryDatePeriod = (expiryDate: string, period: string) => {
  const periods = {
    month: Number(expiryDate.split("/")[0]),
    year: Number(expiryDate.split("/")[1])
  };

  if (periods.year > 2000 && periods.year < 2100) {
    periods.year -= 2000;
  }

  return periods[period];
};

export const validateExpiryDate = (expiryDate: string) => {
  const month = getExpiryDatePeriod(expiryDate, "month");
  const year = getExpiryDatePeriod(expiryDate, "year");
  const currentYear = new Date().getFullYear() - 2000;
  const currentMonth = new Date().getMonth() + 1;

  const validateExpression =
    month <= 12 &&
    ((month > 0 && year > currentYear) || (month >= currentMonth && year == currentYear)) &&
    year <= 40;

  return validateExpression;
};

let lsState = {};
let lsQueue = {};
const lsCallbacks = {};

const execLsQueueAndClear = url => {
  lsQueue[url].forEach(callback => callback());
  lsQueue[url] = [];
};

export const loadScript = (url: string, callback: () => void, opts) => {
  if (!lsQueue[url]) {
    lsQueue[url] = [];
  }
  const state = lsState[url];

  if (state === "loaded") {
    lsQueue[url].push(callback);
    execLsQueueAndClear(url);
  } else if (state === "init") {
    lsQueue[url].push(callback);
  } else {
    lsQueue[url].push(callback);
    let script = document.createElement("script");
    script.type = "text/javascript";
    lsState[url] = "init";

    if (opts && opts.id) {
      script.id = opts.id;
    }

    if (script.readyState) {
      //IE
      script.onreadystatechange = function() {
        if (script.readyState == "loaded" || script.readyState == "complete") {
          script.onreadystatechange = null;
          lsState[url] = "loaded";
          execLsQueueAndClear(url);
        }
      };
    } else {
      //Others
      script.onload = function() {
        lsState[url] = "loaded";
        execLsQueueAndClear(url);
      };
    }
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
};

export const removeCyclic = (obj) => {
  const seenObjects = [];
  const detect = (obj) => {
    if (obj && typeof obj === "object") {
      if (seenObjects.indexOf(obj) !== -1) {
        return true;
      }
      seenObjects.push(obj);
      for (var key in obj) {
        if (key.startsWith("__reactInternalInstance$")) {
          obj[key] = {};
        }
        if (obj.hasOwnProperty(key) && detect(obj[key])) {
          return true;
        }
      }
    }
    return false;
  }
  return detect(obj);
}

export const detectOS = (showVersion, shortOS) => {
  let os;
  let result;
  let osVersion ;
  let nAgt = navigator.userAgent;
  let nVer = navigator.appVersion;
  const clientStrings = [
    {s:'Windows 10', r:/(Windows 10|Windows NT 10.0)/},
    {s:'Windows 8', r:/(Windows 8.1|Windows NT 6.3)/},
    {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
    {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
    {s:'Windows Vista', r:/Windows NT 6.0/},
    {s:'Windows Server 2003', r:/Windows NT 5.2/},
    {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
    {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
    {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
    {s:'Windows 98', r:/(Windows 98|Win98)/},
    {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
    {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
    {s:'Windows CE', r:/Windows CE/},
    {s:'Windows 3.11', r:/Win16/},
    {s:'Windows Phone', r:/Windows Phone/},
    {s:'Android', r:/Android/},
    {s:'Open BSD', r:/OpenBSD/},
    {s:'Sun OS', r:/SunOS/},
    {s:'Linux', r:/(Linux|X11)/},
    {s:'iOS', r:/(iPhone|iPad|iPod)/},
    {s:'Mac OS X', r:/Mac OS X/},
    {s:'Mac OS', r:/(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
    {s:'QNX', r:/QNX/},
    {s:'UNIX', r:/UNIX/},
    {s:'BeOS', r:/BeOS/},
    {s:'OS/2', r:/OS\/2/},
    {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
  ];

  for (let id in clientStrings) {
    let cs = clientStrings[id];

    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'Android':
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
      break;

    case 'Linux':
      osVersion = nVer.indexOf("Linux i686") != -1 ? '32bit' : '64bit';
  }

  if (!os) {
    os = 'Linux';
  }

  result = os + (osVersion ? (' ' + osVersion) : '' );

  if (showVersion) {
    result = osVersion;
  }

  if (shortOS) {
    result = osVersion == 'Phone' ? os + ' phone' : os.split(' ')[0].replace(/\d+/, '');
    result = result.toLowerCase();
  }

  return result;
};

export const findIndex = (items, conditionalFunction) => {
  let index = -1;
  items.some((el, i) => {
    let conditionMet = conditionalFunction(el);
    if (conditionMet) {
        index = i;
        return true;
    }
  });

  return index;
}

export const hrefSearch = (key: string) => {
  let parsed = false;
  const params = {};

  const _parse = () => {
    let p_arr = location.search.substring(1).split("&");
    let arr;

    for (let n in p_arr) {
      arr = p_arr[n].split("=");
      params[decodeURIComponent(arr[0])] = decodeURIComponent(arr[1]);
    }

    parsed = true;
  };

  if (!parsed) {
    _parse();
  }

  return key in params ? params[key] : false;
};

export const hashSearch = (key: string) => {
  let parsed = false;
  const params = {};

  const _parse = () => {
    let p_arr = location.hash.substring(1).split("&");
    let arr;

    for (let n in p_arr) {
      arr = p_arr[n].split("=");
      params[decodeURIComponent(arr[0])] = decodeURIComponent(arr[1]);
    }

    parsed = true;
  };

  if (!parsed) {
    _parse();
  }

  return key in params ? params[key] : false;
};

const getTopTwoDomainParts = function(v, i) {
  return i > 0;
};

export const setcookie = (name: string, value: string, days: number) => {
  if (typeof document === "undefined") {
    return null;
  };

  // let expires = "";

  // if (days) {
  //   let date = new Date();
  //   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //   expires = "; expires=" + date.toGMTString();
  // }

  // if (location.hostname.indexOf(".pcloud.com") != -1) {
  //   document.cookie =
  //     name + "=" + value + expires + "; domain=.pcloud.com; path=/";
  // } else {
  //   document.cookie = name + "=" + value + expires + "; path=/";
  // }
  let expires = "";

  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + (date: any).toGMTString();
  }

  const domain = window.location.hostname.split('.').filter(getTopTwoDomainParts).join('.');
  const sameSite = (window.location.protocol === "https:") ? ' SameSite=None; Secure;' : '';
  const cookie = `${name}=${value}${expires}; domain=.${domain}; path=/;${sameSite}`;
  console.log("Cookie", cookie);
  document.cookie = cookie;
};

export const setCookieOnSibling = cookie => {
  const { name, value, days } = cookie;
  const linkDomains = ["u.pcloud.link", "e.pcloud.link"];
  const isDlink = !!location.href.match(new RegExp("//[a-z].pcloud.link/"));
  let url = isDlink ? "https://my.pcloud.com/cookie?" : "https://u.pcloud.link/cookie?";

  if (!name && !value) {
    console.log("No cookie");
    return;
  }
  url += 'name=' +  encodeURIComponent(name) + "&value=" + encodeURIComponent(value);
  if (days) {
    url += "&days=" + days;
  }
  console.log("setCookieOnSibling", {cookie, isDlink, url })
  var img = $('<img>');
  img.attr('src', url);
};

export const getCookiesFromSibling = (onSuccess, onError) => {
  const isDlink = !!location.href.match(new RegExp("[a-z0-9]+.pcloud.link"));
  let url = isDlink ? "https://my.pcloud.com/checkcookie?names=" : "https://e.pcloud.link/checkcookie?names=";
  const names = [encodeURIComponent("pcauth"), encodeURIComponent("locationid")];

	$.ajax({
		url: url + names.join(","),
    cache: false,
    dataType: "json",
    method: 'POST',
    xhrFields: {
      withCredentials: true
   }
	}).done(function (ret) {
    console.log("getCookiesFromSibling ret", ret)
    onSuccess(ret);
	}).fail(function (ret) {
		onError(ret);
	});
};

export const rcookie = (name: string) => {
  if (typeof document === "undefined") {
    return null;
  }

  let nameEQ = name + "=";

  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export function getSearch(param: string) {
  return getQuery(param, location.search.substr(location.search.indexOf("?") + 1));
}

export function getHash(param: string) {
  return getQuery(param, location.hash.substr(location.hash.indexOf("#") + 1));
}

function getQuery(param, query) {
  for (let pair of query.split("&")) {
    let [key, val] = pair.split("=");

    if (key === param) {
      return decodeURIComponent(val);
    }
  }
}

export function obLength(obj: {}) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
    }
  }
  return size;
}

function _repl(str: string, repl: {} = {}) {
  str = _properSpaces(str);

  if (!obLength(repl)) {
    return str;
  }

  for (var n in repl) {
    str = str.replace(new RegExp("%" + n + "%", "g"), repl[n]);
  }

  return str;
}

function _properSpaces(str) {
  return str.split(String.fromCharCode(160)).join(" ");
}

export const __ = (
  key: string,
  val?: string,
  repl?: { [string]: mixed },
  description?: string
) => {
  if (typeof (window.pCloudIntl: any) !== "undefined") {
    return window.pCloudIntl.__(key, val, repl, description);
  }

  if (typeof window === "object" && typeof window.__ === "function") {
    return window.__(key, val, repl, description);
  }

  if (val) {
    return _repl(val, repl);
  }

  return _repl(key, repl);
};

export const cleanFloat = (number: number): number => {
	let value = Number(number);
	let decimal = value % 1 == 0 ? 0 : 2;
	let result = Number(value.toFixed(decimal));

	if (result % 1 == 0) {
		decimal = 0;
	}

	return Number(value.toFixed(decimal));
};

const roundInRange = (number: number): number => {
  const range = 0.5;
  const roundedNumber = Math.round(number);
  const difference = Math.abs(number - roundedNumber);

  if (number > roundedNumber) {
    return (difference > range/2) ? (roundedNumber + range) : roundedNumber;
  } else {
    return (difference > range/2) ? (roundedNumber - range) : roundedNumber;
  }
}

export const renderQuota = (value: number, round: boolean): string => {
  const thresh = 1024;
  const units = ['', 'KB','MB', 'GB', 'TB','PB','EB','ZB','YB'];
	let bytes = value;
	let u = 0;

	if (bytes < thresh) return bytes + ' B';

	do {
		bytes /= thresh;
		++u;
	} while (bytes >= thresh);

  if (round) {
    return cleanFloat(roundInRange(bytes)) + " " + units[u];
  }

  return cleanFloat(bytes) + " " + units[u];
};

export function emailInitials(email) {
  const mailPart = email.substr(0, email.indexOf("@"));
  let chars = [mailPart.charAt(0)];

  if (mailPart.search(/[\.\-\_]{1}/) !== -1) {
    chars.push(mailPart.substr(mailPart.search(/[\.\-\_]{1}/) + 1, 1));
  }

  return chars.map(c => c.toUpperCase()).join("");
}

export const debounce = (func: () => void, delay: number) => {
  let timeout = null;

  return (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(e), delay);
  };
};

export const copyToClipboard = ({ textToCopy, onSuccess = () => {}, onError = () => {} }) => {
  try {
    navigator.clipboard.writeText(textToCopy).then(() => {
      onSuccess();
    }, () => {
      onError();
    });
  } catch (err) {
    console.log("🚀 ~ file: utils.js ~ line 573 ~ copyToClipboard ~ err", err);
    onError();
  }
};

export const convertStringToAsterisk = (stringLenght) => {
  let result = "";
  for (let index = 0; index < stringLenght; index++) {
    result += "*";
  }
  return result;
};

export const detectIsMobile = () => {
  return window.matchMedia("only screen and (max-width: 760px)").matches && navigator.maxTouchPoints || 'ontouchstart' in document.documentElement;
};

export const detectIsRetina = () => {
  return window.devicePixelRatio > 1;
};

export const getLastFourChars = (chars) => {
  return String(chars).slice(-4);
};

export const getFirstChars = (chars) => {
  return String(chars).slice(0, 1);
};

const mathRandomCrypto = ()  =>{
  return (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295);
};

const shuffleString = (str, maxlength) => {
  let shuffledString = str
    .split("")
    .sort(() => {
      return 0.5 - mathRandomCrypto();
    })
    .join("");
  if (maxlength > 0) {
    shuffledString = shuffledString.substr(0, maxlength);
  }

  return shuffledString;
};

function generateString(characters, length) {
  let result = "";
  const charactersLength = characters.length;
  for ( let i = 0; i < length; ++i ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const generateNewPassword = (length = 16, selectedRules = {}) => {
  const { symbolChecked = false, digitsChecked = false, uppercaseChecked = false, lowercaseChecked = false } = selectedRules;
  const rules = [];

  if (symbolChecked) {
    rules.push({ chars: "!@#$&*?|%+-_./:;=()[]{}", min: 1 });
  }
  if (digitsChecked) {
    rules.push({ chars: "0123456789", min: 1 });
  }
  if (uppercaseChecked) {
    rules.push({ chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", min: 1 });
  }

  if (lowercaseChecked) {
    rules.push({ chars: "abcdefghijklmnopqrstuvwxyz", min: 1 });
  }

  let allChars = "",
    allMin = 0;
  rules.forEach((rule) => {
    allChars += rule.chars;
    allMin += rule.min;
  });
  if (length < allMin) {
    length = allMin;
  }

  let pswd = "";
  rules.forEach((rule) => {
    if (rule.min > 0) {
      pswd += shuffleString(rule.chars, rule.min);
    }
  });

  pswd += generateString(allChars, length - allMin);

  return shuffleString(pswd);
};

export const generateDeviceID = () => {
  let IDs = [];
  for (var i = 0; i < 4; i++) {
    let code = Math.random()
      .toString(36)
      .substr(2, 9);
    IDs.push(code);
  }

  return IDs.join("");
};

export const getDeviceID = () => {
  const fetchDeviceIdData = async () => {
    if (typeof chrome !== 'undefined' && chrome.storage) {
      let deviceid = await getItemFromLocalStorage("deviceid");

      if (!deviceid) {
        deviceid = generateDeviceID();
        setItemToLocalStorage({ deviceid: deviceid });
      };

      return new Promise((resolve, reject) => {
        resolve(deviceid);
      });
    }
    
    if (!window.localStorage.getItem("deviceid") && !rcookie("deviceid")) {
      const id = generateDeviceID();
      window.localStorage.setItem('deviceid', id);
      setcookie("deviceid", id, 365);
      return new Promise((resolve, reject) => {
        resolve(id);
      });
    };

    if (rcookie("deviceid")) {
      const id = rcookie("deviceid");

      window.localStorage.setItem('deviceid', id);
      return new Promise((resolve, reject) => {
        resolve(id);
      });
    }
    
    if (window.localStorage.getItem("deviceid")) {
      const id = window.localStorage.getItem("deviceid");

      setcookie("deviceid", id, 365);
      return new Promise((resolve, reject) => {
        resolve(id);
      });
    }

    return new Promise((resolve, reject) => {
      resolve("");
    });
  }

  return fetchDeviceIdData();
};

export const convertTimeFromMilliseconds = (time) => {
  let timeInSeconds = Math.round(time / 1000);

  if (timeInSeconds < 60) {
    return timeInSeconds + (timeInSeconds === 1 ? " " + __("second") : " " + __("seconds"))
  }

  let timeInMinutes = Math.round(timeInSeconds / 60);

  if (timeInMinutes < 60) {
    return timeInMinutes + (timeInMinutes === 1 ? " " + __("minute") : " " + __("minutes"))
  }

  let timeInHours = Math.round(timeInMinutes / 60);

  if (timeInHours <= 24) {
    return timeInHours + (timeInHours === 1 ? " " + __("hour") : " " + __("hours"));
  }

  let timeInDays = Math.round(timeInHours / 24);

  if (timeInDays < 7) {
    return timeInDays + (" days");
  }
}

export function onDocumentReady(func: Function) {
  if (document.readyState == "complete") {
    func();
  } else {
    $(window).on("load", func);
  }
}

export const setItemToLocalStorage = (item) => {
  if (typeof chrome !== 'undefined' && chrome.storage) {
    chrome.storage.local.set(item);
  } else if (typeof storage !== 'undefined') {
    storage.local.set(item);
  } else if (typeof localStorage !== "undefined") {
    const key = Object.keys(item)[0];
    localStorage.setItem(key, item[key]);
  }
};

export const getItemFromLocalStorage = async (key) => {
  return new Promise((resolve, reject) => {
    try {
      if (typeof chrome !== 'undefined' && chrome.storage) {
        chrome.storage.local.get(key, (value) => {
          resolve(value[key]);
        });
      } else if (typeof storage !== 'undefined') {
        storage.local.get(key).then((value) => {
          resolve(value[key]);
        });
      } else if (typeof localStorage !== 'undefined') {
        resolve(localStorage.getItem(key));
      }
    } catch (ex) {
      console.log("🚀 ~ file: utils.js ~ line 751 ~ returnnewPromise ~ ex", ex)
      reject("");
    }
  });
};

export const fetchDataFromStorage = async (key) => {
  if (rcookie(key)) {
    return rcookie(key);
  }

  return getItemFromLocalStorage(key);
};
export const validUrl = (text) => {
  return text.indexOf("http://") !== -1 || text.indexOf("https://") !== -1;
};

export const formatTimeXX = (time: number) => {
  const timeStr = String(time);
  return timeStr.length === 1 ? "0" + timeStr : timeStr;
};

export function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\	".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export function secondsToStr (seconds) {
  if (seconds === Infinity) {
    return __("Centuries");
  }

  const years = Math.floor(seconds / 31536000);
  if (years) {
    return `${years} ${years > 1 ? __("years") : __("year")}`;
  }
  const days = Math.floor((seconds %= 31536000) / 86400);
  if (days) {
    return `${days} ${days > 1 ? __("days") : __("day")}`;
  }
  const hours = Math.floor((seconds %= 86400) / 3600);
  if (hours) {
    return `${hours} ${hours > 1 ? __("hours") : __("hour")}`;
  }
  const minutes = Math.floor((seconds %= 3600) / 60);
  if (minutes) {
    return `${minutes} ${minutes > 1 ? __("minutes") : __("minute")}`;
  }
  const secondsFormatted = seconds % 60;
  if (secondsFormatted) {
    return `${secondsFormatted} ${secondsFormatted > 1 ? __("seconds") : __("second")}`;
  }
  return __("Less than a second");
}