import { __ } from "./utils";

export const DOWNLOAD_DRIVE_BUTTON_OPTIONS = {
    windows: [
      {
        type: "button",
        text: __("Download"),
        link: "https://www.pcloud.com/how-to-install-pcloud-drive-windows.html?download=windows-10-64bit",
        description: null
      },
      {
        type: "select",
        text: __("choose your version"),
        options: [
          {
            optionText: __("windows_64bit_download_versions", "Windows 64-bit (7 or later)"),
            link: "https://www.pcloud.com/how-to-install-pcloud-drive-windows.html?download=windows-10-64bit"
          },
          {
            optionText: __("windows_32bit_download_versions", "Windows 32-bit (7 or later)"),
            link: "https://www.pcloud.com/how-to-install-pcloud-drive-windows.html?download=windows-10-32bit"
          },
          {
            optionText: "Windows XP",
            link: "https://www.pcloud.com/how-to-install-pcloud-drive-windows.html?download=windows-xp"
          }
        ],
        description: null
      }
    ],
    mac: [
      {
        type: "button",
        text: __("Download"),
        link: "https://www.pcloud.com/how-to-install-pcloud-drive-mac-os.html?download=mac",
        description: __("macos_download_intel_description", "pCloud Drive for Mac with an Intel processor")
      },
      {
        type: "button",
        text: __("macos_download_apple_silicon", "Download for M1 Mac"),
        link: "https://www.pcloud.com/how-to-install-pcloud-drive-apple-silicon.html?download=macm1",
        description: __("macos_download_m1_description", "pCloud Drive for Mac with Apple Silicon")
      }
    ],
    linux: [
      {
        type: "button",
        text: __("Download"),
        link: "https://www.pcloud.com/how-to-install-pcloud-drive-linux.html?download=electron-64",
        description: null
      },
      {
        type: "select",
        text: __("choose your version"),
        options: [
          {
            optionText: "Linux 64bit",
            link: "https://www.pcloud.com/how-to-install-pcloud-drive-linux.html?download=electron-64"
          },
          {
            optionText: "Linux 32bit",
            link: "https://www.pcloud.com/how-to-install-pcloud-drive-linux.html?download=electron-32"
          }
        ],
        description: null
      }
    ],
  }