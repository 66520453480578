import { hrefSearch, setcookie, rcookie } from "./utils";

export const CJ_PAGE_TYPE_MAP = {
  subscriptions: "accountCenter",
  backup: "productDetail",
  europe: "productDetail",
  plans: "productDetail",
  crypto: "productDetail",
  bregister: "accountSignup",
  family: "productDetail",
  testimonials: "productDetail",
  "cancel-subscription": "accountCenter",
  install: "productDetail",
  "support-rating": "accountCenter",
  "faq-page": "productDetail",
  "faq-page-results": "searchResults",
  home: "homepage",
  "home-eu": "homepage",
  downloads: "productDetail"
}

export const CJ_PLANS_PREFIX = {
  1: "PREMIUM",
  3: "PREMIUM_PLUS",
  14: "FAMILY2TB",
  15: "FAMILY4TB",
  17: "CUSTOM",
  "17_1_A": "CUSTOM",
  "17_4_A": "CUSTOM",
  "17_20_A": "CUSTOM",
  "17_20_C": "CUSTOM",
  101: "CRYPTO",
  111: "EFH",
  112: "RELOCATION"
}

export const saveCJCookie = () => {
  const cjevent = hrefSearch("cjevent");

  if (cjevent) {
    const timeNow = new Date();
    const timeNowUTC = timeNow.getTime() - timeNow.getTimezoneOffset()*60000;
    const CJexpiration = Number.parseInt((timeNowUTC / 1000) + 395 * 24 * 60 * 60);

    setcookie("cje", cjevent, 395);
    setcookie("cjexpiration", CJexpiration, 395);
  }
}

export const getCJData = () => {
  let CJData = null;

  const CJevent = hrefSearch("cjevent") || rcookie("cje");
  let CJexpiration = rcookie("cjexpiration");

  if (CJevent) {
    CJData = {};
    CJData.cjtoken = CJevent;

    if (CJexpiration) {
      CJData.cjexpiration = CJexpiration;
    } else {
      const timeNow = new Date();
      const timeNowUTC = timeNow.getTime() - timeNow.getTimezoneOffset()*60000;
  
      CJexpiration = Number.parseInt((timeNowUTC / 1000) + 395 * 24 * 60 * 60);
      CJData.cjexpiration = CJexpiration;
    }
  }

  return CJData;
}

export const sendCJPageVisitTag = sitePageData => {
  sitePageData = sitePageData || {};
  const cjCookieData = getCJData();

  if (!cjCookieData) {
    return;
  }

  if (!window.cj) {
    window.cj = {};
  };

  cj.sitePage = {
      ...sitePageData,
      enterpriseId: 1563104
  };

  (function(a,b,c,d){ a='https://www.mczbf.com/tags/11560/tag.js'; b=document;c='script';d=b.createElement(c);d.src=a; d.type='text/java'+c;d.async=true;
  d.id='cjapitag'; a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a) })();
};

export const sendCJConversionTag = orderData => {
  //Prevent firing the conversion tag
  const shouldExecute = false;

  if (!shouldExecute) {
    return;
  }

  orderData = orderData || {};
  const cjCookieData = getCJData();

  if (!cjCookieData) {
    return;
  }

  if (!window.cj) {
    window.cj = {};
  };

  cj.order = {
      ...orderData,
      enterpriseId: 1563104,
      pageType: "conversionConfirmation",
      actionTrackerId: "426709"
  };

  if (cjCookieData.cjtoken) {
    cj.order.cjeventOrder = cjCookieData.cjtoken;
  }

  (function(a,b,c,d){ a='https://www.mczbf.com/tags/11560/tag.js'; b=document;c='script';d=b.createElement(c);d.src=a; d.type='text/java'+c;d.async=true;
  d.id='cjapitag'; a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a) })();
}

export const sendCJTokenOnLogin = (apiMethod, auth) => {
  const cjCookieData = getCJData();

  if (!cjCookieData) {
    return;
  }

  if (auth) {
    cjCookieData.auth = auth;
  }

  if (cjCookieData.cjtoken) {
    apiMethod("affiliate/cj/settoken", cjCookieData, () => {}, {})
  }
}