// @flow

import React from "react";
import styled, { css } from "styled-components";

export type Props = {
  id?: string,
  text?: string,
  name: ?string,
  color?: string,
  size?: "small" | "big",
  checked?: boolean,
  children?: any,
  isDisabled?: boolean,
  onChange: (any) => void,
};

const InputStyledCheckbox = ({
  id = "",
  text = "",
  name = "",
  size = "big",
  color = "#17bed0",
  children = null,
  isDisabled = false,
  isMixed = false,
  ...rest
}: Props) => (
  <Container
    isMixed={isMixed}
    size={size}
    color={color}
    className="checkbox-checkmark"
    isDisabled={isDisabled}
  >
    {text ? <TextWrapper>{text}</TextWrapper> : null}
    {children}
    <input id={id} type="checkbox" disabled={isDisabled} name={name} {...rest} />
    <Checkmark isMixed={isMixed} className="input-checkbox" data-testid="terms-checkbox" size={size} />
  </Container>
);

export default InputStyledCheckbox;

const Container = styled.label`
  display: block;
  position: relative;
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  line-height: ${(props) => (props.size == "small" ? "13px" : "20px")};
  padding-left: ${(props) => (props.size == "small" ? "13px" : "18px")};
  padding-bottom: 0;
  /* margin-bottom: 12px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &:hover input ~ span {
    background-color: #fff;
  }

  input[type="checkbox"]:checked ~ span {
    background-color: ${(props) => props.color};
    border: ${(props) => `2px solid ${props.color}`};
    border-radius: 2px;
  }

  input[type="checkbox"]:checked ~ span:after {
    display: block;
  }

  ${(props) =>
    props.isMixed
      ? css`
          span:after {
            top: 1.5px;
            left: 4.5px;
            top: ${(props) => (props.size == "small" ? "1px" : "1px")};
            left: ${(props) => (props.size == "small" ? "4px" : "3.5px")};
            height: ${(props) => (props.size == "small" ? "8px" : "8px")};
            width: 0;
            border: solid white;
            border-width: 0 2px 0 0;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        `
      : css`
          span:after {
            top: 0px;
            left: ${(props) => (props.size == "small" ? "3px" : "5px")};
            height: ${(props) => (props.size == "small" ? "5px" : "10px")};
            width: ${(props) => (props.size == "small" ? "2px" : "4px")};
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        `};
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.size == "small" ? "14px" : "18px")};
  height: ${(props) => (props.size == "small" ? "14px" : "18px")};
  border: ${(props) => (props.size == "small" ? "1px solid #a1a1a1" : "2px solid #a1a1a1")};
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #fff;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: inline-block;
  margin-left: 10px;
`;
