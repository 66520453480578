// @flow

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { __ } from "../utils";

import MinusIcon from "../images/svg/minus-icon.svg";
import PlusIcon from "../images/svg/plus-icon.svg";

type Props = {
  value?: number,
  min: number,
  max: number,
  disabled: boolean,
  step: number,
  title?: string,
  suffix?: string,
  onChange: () => void,
  onUp: (currentValue: number) => void,
  onDown: (currentValue: number) => void,
  autoFocus: boolean
};

const RangeNumberInput = ({
  value,
  min = 0,
  max = 100,
  disabled = false,
  step = 1,
  suffix = "",
  title = "",
  onChange = () => {},
  onUp = null,
  onDown = null,
  autoFocus = true
}: Props) => {
  const [disabledMin, setDisabledMin] = useState(false);
  const [disabledMax, setDisabledMax] = useState(false);
  const [inputValue, setInputValue] = useState();

  const inputValueTimeoutHandler = useRef(null);

  useEffect(() => {
    if (inputValue >= 0) {
      if (inputValueTimeoutHandler.current) {
        clearTimeout(inputValueTimeoutHandler.current);
      }
      
      inputValueTimeoutHandler.current = setTimeout(() => {
        onChange(inputValue, (newValue) => {
          setInputValue(newValue);
        });
      }, 500)
  
      return () => {
        if (inputValueTimeoutHandler.current) {
          clearTimeout(inputValueTimeoutHandler.current);
        }
      };
    }
  }, [inputValue]);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value])

  useEffect(() => {
    setDisabledMin(disabled || value <= min);
    setDisabledMax(disabled || value >= max);
  }, [disabled, max, min, value]);

  const onChangeInput = e => {
    let val = e.target.value;

    if (val !== "" && val >= 0 && val > max) {
      val = max;
    }

    setInputValue(val ? parseInt(val) : val);
  };

  const validateUsersNumber = e => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);
    let val = e.target.value.replace(/\s+/g, "");

    val = val.trim();
    if (!/[0-9 ]/.test(pressedCharacter)) {
      e.preventDefault();
    }
  };

  const onBtnUpClick = () => {
    if (disabled) {
      return;
    }

    const oldValue = parseFloat(value || 0);
    
    if (onUp) {
      onUp(oldValue);
      return;
    }
    
    let newVal = 0;
    if (oldValue >= max) {
      newVal = oldValue;
    } else {
      newVal = oldValue + step;
    }
    onChange(parseInt(newVal), (newValue) => {
      setInputValue(newValue);
    });
  };

  const onBtnDownClick = () => {
    if (disabled) {
      return;
    }

    const oldValue = parseFloat(value || 0);

    if (onDown) {
      onDown(oldValue);
      return;
    }

    let newVal = 0;

    if (oldValue <= min) {
      newVal = oldValue;
    } else {
      newVal = oldValue - step;
    }
    
    onChange(parseInt(newVal), (newValue) => {
      setInputValue(newValue);
    });
  };

  return (
    <Wrapper>
      {title ? <Title>{title}:</Title> : null}
      <ButtonWrapper>
        <Button disabled={disabledMin} onClick={onBtnDownClick}>
          <MinusIcon width="14px" fill={disabledMin ? "#dadadc" : "#ff3434"} />
        </Button>
        <div>
        <InputUsers
          type="number"
          min={min}
          max={max}
          value={inputValue}
          onInput={onChangeInput}
          onKeyPress={validateUsersNumber}
          onPaste={validateUsersNumber}
          autoFocus={autoFocus}
          disabled={disabled}
          widthCH={inputValue ? inputValue.toString().length + 1 : 2}
        />
        { suffix ? <Suffix>{suffix}</Suffix> : null }
        </div>
        <Button disabled={disabledMax} onClick={onBtnUpClick}>
          <PlusIcon width="14px" fill={disabledMax ? "#dadadc" : "#86c600"} />
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default RangeNumberInput;

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #999;
  margin-bottom: 7px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  padding: 5px;
  margin: 0 auto;
  border-radius: 20px;
  border: solid 1px #eee;
  background-color: #f2f2f2;
  box-sizing: border-box;
`;

const InputUsers = styled.input`
  max-width: 100px;
  font-size: 17px;
  /* width: 50px; */
  font-weight: bold;
  border: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: transparent;
  outline: none;
  text-align: center;
  width: ${({widthCH}) => widthCH + "ch"};
  /* Firefox */
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Suffix = styled.span`

`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  width: 30px;
  height: 30px;
  border-radius: 18px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  box-sizing: border-box;
`;
